import React from "react"

import { Spacer } from "components"

// import flexible content modules
import Banner from "./Banner"
import CallToAction from "./CallToAction"
import Embeds from "./Embeds"
import ExpressEntryDraw from "./ExpressEntryDraw"
import ExpressEntryCalculator from "./ExpressEntryCalculator"
import LanguageEquivalencyChart from "./LanguageEquivalencyChart"
import ToolsWidget from "./ToolsWidget"
import Faq from "./Faq"
import Forms from "./Forms"
import IconWall from "./IconWall"
import LargeButtons from "./LargeButtons"
import News from "./News"
import Newsletter from "./Newsletter"
import Immigreat from "./Immigreat"
import PageHeader from "./PageHeader"
import Stats from "./Stats"
import Steps from "./Steps"
import StyledTable from "./StyledTable"
import Testimonials from "./Testimonials"
import TextBlock from "./TextBlock"
import TextImage from "./TextImage"
import Tiles from "./Tiles"

const getMargin = (size) => {
  if (size === "sm") {
    return { xs: 30 }
  } else if (size === "md") {
    return { xs: 60 }
  } else if (size === "lg") {
    return { xs: 90 }
  } else {
    return { xs: 0 }
  }
}

const getPadding = (size) => {
  if (size === "sm") {
    return { xs: 30 }
  } else if (size === "md") {
    return { xs: 60 }
  } else if (size === "lg") {
    return { xs: 90 }
  } else {
    return { xs: 0 }
  }
}

export default (props) => {
  const { rows, data } = props
  if (!!rows) {
    return rows
      .filter((o) => o !== null)
      .map(({ __typename, ...rowData }, index) => {
        const type = __typename.split("_").slice(-1)[0]
        const components = {
          Banner,
          CallToAction,
          Embeds,
          ExpressEntryDraw,
          ExpressEntryCalculator,
          LanguageEquivalencyChart,
          ToolsWidget,
          IconWall,
          LargeButtons,
          News,
          Newsletter,
          Immigreat,
          PageHeader,
          Faq,
          Forms,
          Steps,
          Stats,
          StyledTable,
          Testimonials,
          TextBlock,
          TextImage,
          Tiles
        }
        const Component = components[type]

        return (
          Component && (
            <Spacer
              key={index}
              mt={getMargin(rowData?.settings?.marginTop)}
              mb={getMargin(rowData?.settings?.marginBottom)}
            >
              <Component
                pt={getPadding(rowData?.settings?.paddingTop)}
                pb={getPadding(rowData?.settings?.paddingBottom)}
                {...rowData}
                {...data}
              />
            </Spacer>
          )
        )
      })
  }
}
