import React, { useState, useEffect, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faCheckCircle,
  faTimesCircle,
  faCheckSquare,
  faSearch
} from "@fortawesome/free-solid-svg-icons"
import { getOccupationByName } from "../../utils/api/tool/noc-occupations"
import { useQuery } from "react-query"
import LinkButton from "./LinkButton"

const OccupationNOCFinder = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [selectedOccupation, setSelectedOccupation] = useState(null)
  const [currentFocus, setCurrentFocus] = useState(-1)
  const searchListboxRef = useRef(null)
  const abortControllerRef = useRef(null)

  const { data: nocOccupations = [], isFetching, error } = useQuery({
    queryKey: ["nocOccupations", searchQuery],
    queryFn: ({ signal }) => getOccupationByName(searchQuery, signal),
    enabled: searchQuery.length > 2,
    onSettled: () => setIsLoading(false),
    retry: false,
  })

  const handleInputChange = (e) => {
    const query = e.target.value
    setSearchQuery(query)
    if (query.length > 2) {
      setIsLoading(true)
      setShowResults(true)
    } else {
      setShowResults(false)
    }
  }

  const handleClearSearch = () => {
    setSearchQuery("")
    setShowResults(false)
    setSelectedOccupation(null)

    // Abort any ongoing request when clearing the search
    if (abortControllerRef.current) {
      abortControllerRef.current.abort()
    }
  }

  const handleSelectResult = (item) => {
    setSearchQuery(item.occupation.trim())
    setShowResults(false)
    setSelectedOccupation(item)
  }

  const handleKeyDown = (e) => {
    if (e.keyCode === 40) {
      // Down arrow
      e.preventDefault()
      setCurrentFocus((prev) =>
        prev < nocOccupations.length - 1 ? prev + 1 : 0
      )
    } else if (e.keyCode === 38) {
      // Up arrow
      e.preventDefault()
      setCurrentFocus((prev) =>
        prev > 0 ? prev - 1 : nocOccupations.length - 1
      )
    } else if (e.keyCode === 13) {
      // Enter key
      e.preventDefault()
      if (currentFocus > -1 && nocOccupations[currentFocus]) {
        handleSelectResult(nocOccupations[currentFocus])
      }
    }
  }

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort()
      }
    }
  }, [])

  useEffect(() => {
    if (currentFocus > -1 && searchListboxRef.current) {
      const activeItem = searchListboxRef.current.querySelector(
        `.search-result-item:nth-child(${currentFocus + 1})`
      )
      if (activeItem) {
        activeItem.scrollIntoView({ block: "nearest" })
      }
    }
  }, [currentFocus])

  const getSkillStatus = (teer) => {
    return teer <= 3 ? (
      <>
        <span className="text-green-500">SKILLED</span>{" "}
        <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />
      </>
    ) : (
      <>
        <span className="text-ca-red-500">NOT SKILLED</span>{" "}
        <FontAwesomeIcon icon={faTimesCircle} className="text-ca-red-500" />
      </>
    )
  }

  const getSkillLevel = (teer) => {
    switch (teer) {
      case "0":
      case "1":
        return "A"
      case "2":
      case "3":
        return "B"
      case "4":
      case "5":
        return "C"
      case "6":
      case "7":
        return "D"
      default:
        return "-"
    }
  }

  const toTitleCase = (str) => {
    return str.replace(/\w\S*/g, (word) => {
      return word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
    })
  }

  return (
    <section
      className={`container min-h-[300px] md:min-h-[400px] text-sm md:text-base lg:text-lg py-4 md:py-6 text-gray-700 flex items-center justify-center px-6 2xl:px-0`}
    >
      <div className="w-full max-w-2xl mx-auto">
        <div className="relative">
          <h2 className="font-san font-medium mb-4 text-2xl text-ca-red-600">
            Is my occupation considered skilled?
          </h2>
          <p className="mb-1">Fill in your occupation below to find out:</p>

          <div className="relative">
            <input
              type="text"
              className="w-full px-4 py-3 text-gray-700 bg-white border border-gray-300 focus:outline-none focus:ring-2 focus:ring-ca-red-600 focus:border-transparent transition-all duration-200 ease-in-out"
              placeholder="Search..."
              value={searchQuery}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              aria-label="Search input"
            />
            <button
              className="absolute right-3 top-1/2 -translate-y-1/2 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={handleClearSearch}
            >
              {searchQuery ? (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="h-5 w-5 text-gray-600 hover:text-ca-red-500"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faSearch}
                  className="h-5 w-5 text-gray-600 hover:text-ca-red-500"
                />
              )}
            </button>
          </div>

          {showResults && (
            <div
              ref={searchListboxRef}
              className="absolute mt-1 w-full bg-white shadow-lg border border-gray-200 max-h-96 overflow-y-auto z-50"
            >
              {isLoading || isFetching ? (
                <div className="p-4 text-center">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto"></div>
                </div>
              ) : error ? (
                <div className="p-4 bg-red-50 text-center">
                  <p className="text-sm text-red-500">
                    {error.message || "Something went wrong. Please try again."}
                  </p>
                </div>
              ) : nocOccupations.length === 0 ? (
                <div className="p-2 bg-gray-50">
                  <p className="text-sm text-gray-500 text-center">
                    No results found
                  </p>
                </div>
              ) : (
                nocOccupations.map((item, index) => (
                  <div
                    key={item.id}
                    className={`p-2 hover:bg-gray-50 cursor-pointer transition duration-150 ease-in-out text-left search-result-item ${
                      currentFocus === index ? "bg-gray-100" : ""
                    }`}
                    onClick={() => handleSelectResult(item)}
                  >
                    <div className="text-base text-gray-700 font-medium">
                      {item.occupation}
                    </div>
                    <div className="text-xs text-gray-500">
                      NOC: {item.noc_code}
                    </div>
                  </div>
                ))
              )}
            </div>
          )}
        </div>

        {selectedOccupation && (
          <div class="mt-8 p-6 bg-white shadow-lg border border-gray-200 text-left">
            <h2 className="text-2xl font-bold mb-4">
              This occupation is{" "}
              {getSkillStatus(selectedOccupation.noc_code.toString().charAt(1))}
            </h2>
            <div className="grid grid-cols-1 gap-1">
              <div className="mb-2">
                <p className="mb-0 text-gray-600">Title:</p>
                <p className="font-medium">
                  {toTitleCase(selectedOccupation.occupation)}
                </p>
              </div>
              <div className="mb-2">
                <p className="mb-0 text-gray-600">NOC:</p>
                <p className="font-medium">
                  <a
                    href={`https://www23.statcan.gc.ca/imdb/p3VD.pl?CLV=5&amp;CST=01052021&amp;CVD=1322870&amp;Function=getVD&amp;MLV=5&amp;TVD=1322554&amp;CPV=${selectedOccupation.noc_code}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline decoration-ca-red-500 text-black hover:text-ca-red-500"
                  >
                    {selectedOccupation.noc_code}
                  </a>
                </p>
              </div>
              <div className="mb-2">
                <p className="mb-0 text-gray-600">TEER:</p>
                <p className="font-medium">
                  {selectedOccupation.noc_code.toString().charAt(1)}
                </p>
              </div>
              <div className="mb-2">
                <p className="mb-0 text-gray-600">Skill Level:</p>
                <p className="font-medium">
                  {getSkillLevel(
                    selectedOccupation.noc_code.toString().charAt(1)
                  )}
                </p>
              </div>
              {["2", "3"].includes(
                selectedOccupation.noc_code.toString().charAt(1)
              ) && (
                <div className="mb-2 flex items-center font-medium">
                  <FontAwesomeIcon
                    icon={faCheckSquare}
                    className="text-xl text-green-500 mr-3"
                  />{" "}
                  Eligible for FSTP
                </div>
              )}
            </div>

            <div className="flex items-center justify-center gap-4 mt-4">
              <LinkButton to="https://cta-service-cms2.hubspot.com/web-interactives/public/v1/track/redirect?encryptedPayload=AVxigLKFe9QnhP47pPAkZopYbT1B0iqh6BSVtTsbIlykonfG9OYhmsOjAGGDhxVu5KlipZg0emUjCCUqVKpQGHJ914PB7rTPpCIwpzOeD5LV8%2BenmKQ%3D&webInteractiveContentId=186360987216&portalId=45842344">
                Book a Consultation →
              </LinkButton>
            </div>
          </div>
        )}
      </div>
    </section>
  )
}

export default OccupationNOCFinder
