import React, { useState } from 'react';
import LanguageEquivalencyChart from "../flexibleContent/LanguageEquivalencyChart"
import OccupationNOCFinder from '../flexibleContent/OccupationNOCFinder';

export const Modal = ({ isOpen, onClose, id = "modal", children }) => {
    if (!isOpen) return null;

    return (
      <div style={{ "zIndex": 1001 }} className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id={id}>
          <div className="relative mt-30 p-5 border w-3/4 shadow-lg rounded-md bg-white">
              <button
                onClick={onClose}
                className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                aria-label="Close"
              >
                  <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                      <path d="M6 18L18 6M6 6l12 12"></path>
                  </svg>
              </button>
              <div className="text-center">
                  <div className="flex justify-center mb-4">
                      {children}
                  </div>
              </div>
          </div>
      </div>
    );
};

export const ImmiGreatModal = ({ isOpen, onClose }) => {
    const [activeTab, setActiveTab] = useState(0);
    if (!isOpen) return null;

    const tabs = [
        { label: 'CRS Calculator', immigreatLink: "https://canadaabroad.immigreat.io/embed/express-entry-calculator" },
        { label: 'OINP Calculator', immigreatLink: "https://canadaabroad.immigreat.io/embed/oinp-calculator" },
        { label: "BC PNP Calculator", immigreatLink: "https://canadaabroad.immigreat.io/embed/bc-pnp" },
        { label: 'NOC Code Finder', immigreatLink: "https://canadaabroad.immigreat.io/embed/full-screen/occupation" },
        { label: 'Language Chart', immigreatLink: "" }
    ];

    const handleUrlParams = (immigreatLink) => {
        let urlParams = new URLSearchParams();

        if (typeof window !== 'undefined') {
            if (document.referrer) {
                urlParams.append('referral_url', encodeURIComponent(document.referrer));
            }
            if (window.location.href) {
                urlParams.append('current_url', encodeURIComponent(window.location.href));
            }
        }

        return `${immigreatLink}?${urlParams.toString()}`;
    };

    return (
        <div style={{ zIndex: 1001 }} className="fixed inset-0 z-50 flex items-center justify-center bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
            <div className="relative mt-30 p-5 border w-3/4 shadow-lg rounded-md bg-white">
                <button
                    onClick={onClose}
                    className="absolute top-0 right-0 mt-2 mr-2 text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500"
                    aria-label="Close"
                >
                    <svg className="h-6 w-6" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                        <path d="M6 18L18 6M6 6l12 12"></path>
                    </svg>
                </button>

                <div className="text-center">
                    <div className="flex justify-center mb-4">
                        {tabs.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setActiveTab(index)}
                                className={`px-4 py-2 mx-2 font-semibold border-b-2 ${activeTab === index
                                    ? 'border-red-500 text-red-500'
                                    : 'border-gray-300 text-gray-500 hover:text-red-500'
                                    }`}
                            >
                                {tab.label}
                            </button>
                        ))}
                    </div>

                    <div className="text-center">
                        {activeTab === 4 ? (
                            // Show LanguageEquivalencyChart only for "Language Chart" tab
                            <LanguageEquivalencyChart />
                        ) : activeTab === 3 ? (
                            <OccupationNOCFinder />
                        ) : (
                            // Show iframe for other tabs
                            <iframe
                                frameBorder="0"
                                id="iframewin"
                                src={handleUrlParams(tabs[activeTab].immigreatLink)}
                                style={{
                                    height: "80vh",
                                    width: "100%",
                                    border: "none"
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};