import fetch from "isomorphic-unfetch"

export const getOccupationByName = async (occupation, signal) => {
  try {
    const response = await fetch("/.netlify/functions/noc-occupations", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      },
      body: JSON.stringify({
        search: occupation
      }),
      signal
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }

    return await response.json()
  } catch (error) {
    if (error.name === "AbortError") {
      error.isAbortError = true
    } else if (error.message === "Failed to fetch") {
      error.isNetworkError = true
      error.message = "Network error: Please check your internet connection."
    } else {
      error.message = "Something went wrong. Please try again."
    }
    throw error
  }
}
