import React from "react"
import { Link } from "gatsby"

const LinkButton = ({ to, children, className = "" }) => {
  return (
    <Link
      to={to}
      className={`flex items-center gap-2 px-7 py-3 bg-ca-red-600 border border-ca-red-600 text-white text-xl rounded-full shadow-lg transition duration-300 ease-in-out transform hover:bg-ca-red-500 hover:shadow-xl hover:-translate-y-1 ${className}`}
    >
      {children}
    </Link>
  )
}

export default LinkButton
