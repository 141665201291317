import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"
import { Edges } from "components"
import styled from "styled-components"

const LanguageEquivalencyChart = ({ componentBgColor = "" }) => {
  const languages =
    useOptionsQuery()?.themeOptions?.siteOptions?.languages || []
  const [tabValue, setTabValue] = useState(0)
  const handleChange = (event, newValue) => {
    setTabValue(newValue)
  }

  return (
    <div
      className={`text-sm md:text-base lg:text-lg pt-8 pb-8 ${
        componentBgColor ? componentBgColor : ""
      } text-gray-700 rounded-lg max-w-full overflow-x-hidden`}
    >
      <Edges>
        {/* Tabs */}
        <div className="mb-4 overflow-x-auto">
          <div className="flex space-x-4">
            {languages.map((language, index) => (
              <button
                key={index}
                onClick={() => handleChange(null, index)}
                className={`px-4 py-2 text-sm md:text-base lg:text-lg font-medium ${
                  tabValue === index
                    ? `text-ca-red-600 border-b-2 border-ca-red-600`
                    : "text-ca-gray-500 hover:text-ca-gray-600"
                } transition-all duration-300`}
              >
                {language.title}
              </button>
            ))}
          </div>
        </div>

        {/* Table */}
        {languages.map((language, index) => (
          <div key={index} className={tabValue !== index ? "hidden" : ""}>
            {tabValue === index && (
              <div className="mt-5 overflow-x-auto">
                <StyledTable>
                  <thead className="bg-ca-gray-600">
                    <tr className="text-white font-semibold">
                      <th>{language.scores?.levelTitle ?? "CBL"}</th>
                      <th>Listening</th>
                      <th>Reading</th>
                      <th>Writing</th>
                      <th>Speaking</th>
                    </tr>
                  </thead>
                  <tbody>
                    {language.scores?.manageScores.map((score, idx) => (
                      <tr key={idx} className="hover:bg-ca-gray-100">
                        <td className="text-ca-red-600 font-medium">
                          {score?.level ?? score.cblLevel}
                        </td>
                        <td>{score.listening}</td>
                        <td>{score.reading}</td>
                        <td>{score.writing}</td>
                        <td>{score.speaking}</td>
                      </tr>
                    ))}
                  </tbody>
                </StyledTable>
              </div>
            )}
          </div>
        ))}
      </Edges>
    </div>
  )
}

LanguageEquivalencyChart.propTypes = {
  fieldGroupName: PropTypes.string,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      scores: PropTypes.shape({
        manageScores: PropTypes.arrayOf(
          PropTypes.shape({
            cblLevel: PropTypes.string,
            listening: PropTypes.string,
            reading: PropTypes.string,
            writing: PropTypes.string,
            speaking: PropTypes.string
          })
        )
      })
    })
  )
}

export default LanguageEquivalencyChart

const StyledTable = styled.table`
  width: 100%;
  border: 1px solid #ddd;
  border-collapse: collapse;
  border-radius: 8px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: white;

  th,
  td {
    padding: 10px;
    text-align: center;
    border: 1px solid #ddd;
  }
`

const useOptionsQuery = () => {
  const { wp } = useStaticQuery(
    graphql`
      query {
        wp {
          themeOptions {
            siteOptions {
              languages {
                title
                scores {
                  levelTitle
                  manageScores {
                    level
                    listening
                    reading
                    writing
                    speaking
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return wp
}
