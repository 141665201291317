import React from "react"
import LanguageEquivalencyChart from "./LanguageEquivalencyChart"
import OccupationNOCFinder from "./OccupationNOCFinder"

const ToolsWidget = ({ widget, componentBgColor = "" }) => {
  // Later on we will add dynamically more widget
  if (widget === "language_equivalency_chart") {
    return <LanguageEquivalencyChart componentBgColor={componentBgColor} />
  } else if (widget === "occupation_noc_finder") {
    return <OccupationNOCFinder componentBgColor={componentBgColor} />
  }

  // Fallback if no matching widget is found
  return (
    <div style={{ backgroundColor: componentBgColor }}>
      <h2>No Widget Found</h2>
    </div>
  )
}

export default ToolsWidget
